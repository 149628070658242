.servicePage .secOne p {
    font-size: 16px;
}

.servicePage .serviceSlider {
    background-color: var(--themeColor);
    display: flex;
    padding: 30px 0;
}

.servicePage .serviceSlider .contentCard {
    display: flex;
}

.servicePage .serviceSlider .contentCard p {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0 10px;
    margin-bottom: 0;
}

.servicePage .serviceSec {
    padding: 60px 0;
}

.servicePage .serviceSec .head2 {
    font-family: 'poppinsBold';
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
}

.servicePage .serviceSec .head {
    font-size: 54px;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
}

.servicePage .serviceSec .imgDiv {
    max-width: 80%;
    margin: auto;
}

.servicePage .serviceSec .desc {
    font-family: 'poppins';
    font-size: 16px;
    color: #000000;
    margin-bottom: 40px;
    text-align: center;
}

.servicePage .serviceSec .serviceCard {
    background: #F8F8F8;
    padding: 30px 40px;
    border-radius: 30px;
    color: #000000;
    max-width: 580px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.servicePage .serviceSec .serviceCard .imgDiv.lg {
    height: 320px;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.servicePage .serviceSec .serviceCard .imgDiv {
    height: 150px;
    width: 150px;
}

.servicePage .serviceSec .serviceCard .imgDiv img {
    max-height: 100%;
    max-width: 100%;
}

.servicePage .serviceSec .serviceCard h4 {
    font-family: 'poppinsBold';
    font-size: 22px;
    font-weight: bold;
    margin: 20px 0;
}

.servicePage .serviceSec .serviceCard h2 {
    font-family: 'poppinsBold';
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

.jc-center {
    justify-content: center;
}

.servicePage .serviceSec .serviceCard p {
    font-family: 'poppins';
    font-size: 16px;
    margin-bottom: 16px;
}

.servicePage .serviceSec .row {
    margin-bottom: 60px;
}

.servicePage .concSec {
    font-family: 'poppins';
    padding: 30px 0;
    background: #F8F8F8;
    font-size: 18px;
    text-align: center;
}

.servicePage .sevicesWeOffer2 {
    background: #2979FF;
    padding: 50px 0;
    color: #fff;
}

.servicePage .sevicesWeOffer2 h2 {
    font-family: 'poppinsBold';
}

.servicePage .sevicesWeOffer2 h1::before {
    background: #fff;
    height: 2px;
    width: 250px;
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.servicePage .sevicesWeOffer2 h1 {
    font-family: 'poppinsBold';
    position: relative;
    font-size: 52px;
    font-weight: bold;
    margin-bottom: 15px;
    padding-bottom: 10px;
}

.servicePage .sevicesWeOffer2 p {
    font-size: 16px;
    font-family: 'poppins';
}

.fullGraySec {
    padding-bottom: 50px;
}

.fullGraySec .serviceCard {
    background: #F8F8F8;
    padding: 30px 40px;
    border-radius: 30px;
    color: #000000;
    max-width: 580px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fullGraySec .serviceCard .imgDiv.lg {
    height: 320px;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullGraySec .serviceCard h4 {
    font-size: 22px;
    font-weight: bold;
    margin: 20px 0;
}

.fullGraySec .serviceCard h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
}

.jc-center {
    justify-content: center;
}

.fullGraySec .serviceCard p {
    font-size: 16px;
    margin-bottom: 16px;
}

/* White Label Blockchain Solutions Css Start */

.bg-main {
    padding-top: 140px;
    background-color: #E5EEFD;
}

.blockchainConsulting-page .main-container {
    max-width: 1100px;
    margin-inline: auto;
    padding-inline: 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.blockchainConsulting-page .main-container .main-left h2 {
    font-size: 63px;
    font-family: "poppinsBold";
    margin-top: 30px;
}

.blockchainConsulting-page .main-container .main-left p {
    font-size: 18px;
    font-family: "poppins";
    font-weight: 600;
    margin-top: 30px;
}

.blockchainConsulting-page .main-container .main-right img {
    width: 480px;
}

.blockchainConsulting-page .sec-2 {
    background-color: #2979FF;
}

.blockchainConsulting-page .sec-2 .sec2-container {
    max-width: 1180px;
    margin-inline: auto;
    margin-top: 90px;
    padding-inline: 35px;
    padding-block: 33px;
    margin-bottom: 50px;
}

.blockchainConsulting-page .sec-2 p {
    color: #fff;
    font-family: poppins;
    text-align: center;
}

.blockchainConsulting-page .service-section {
    margin-top: 100px;
}

.blockchainConsulting-page .service-section h2 {
    font-size: 45px;
    font-family: "poppinsBold";
    text-align: center;
}

.blockchainConsulting-page .service-section h6 {
    font-size: 16px;
    font-family: "poppinsSemiBold";
    text-align: center;
    padding-inline: 120px;
    padding-top: 10px;
}

.blockchainConsulting-page .service-container1 {
    max-width: 1180px;
    margin-inline: auto;
    margin-top: 50px;
    padding-inline: 40px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 40px;
}

.blockchainConsulting-page .service-container2 {
    max-width: 1180px;
    margin-inline: auto;
    margin-top: 50px;
    padding-inline: 40px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
}

.blockchainConsulting-page .service-section .service-container1 .service-text {
    background-color: #F8F8F8;
    margin-top: 10px;
    width: 50%;
    border-radius: 5px 30px 30px 5px;
    padding-inline: 50px;
    padding-block: 50px;
}

.blockchainConsulting-page .service-section .service-container2 .service-text {
    background-color: #F8F8F8;
    margin-top: 10px;
    width: 50%;
    border-radius: 5px 30px 30px 5px;
    padding-inline: 50px;
    padding-block: 50px;
}

.blockchainConsulting-page .service-section .service-container1 .service-text h4 {
    font-size: 30px;
    font-family: "poppinsSemiBold";
}

.blockchainConsulting-page .service-section .service-container2 .service-text h4 {
    font-size: 30px;
    font-family: "poppinsSemiBold";
}

.blockchainConsulting-page .service-section .service-container1 .service-text p {
    font-size: 18px;
    font-family: "poppins";
}

.blockchainConsulting-page .service-section .service-container2 .service-text p {
    font-size: 18px;
    font-family: "poppins";
}

.blockchainConsulting-page .sec-4 {
    margin-block: 120px;
}

.blockchainConsulting-page .sec-4 h2 {
    font-size: 45px;
    font-family: "poppinsBold";
    text-align: center;
}

.blockchainConsulting-page .sec-4 h6 {
    font-size: 16px;
    font-family: "poppinsSemiBold";
    text-align: center;
    padding-inline: 120px;
    padding-top: 10px;
}

.blockchainConsulting-page .sec-4 .sec4-container {
    max-width: 1180px;
    margin-inline: auto;
    margin-top: 60px;
    padding-inline: 30px;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}

.blockchainConsulting-page .sec-4 .sec4-container .sec4-card {
    background-color: #F8F8F8;
    margin-top: 10px;
    width: 40%;
    border-radius: 10px;
    padding-inline: 50px;
    padding-block: 40px;
    margin-top: 30px;
    height: 420px;
}

.blockchainConsulting-page .sec-4 .sec4-container .sec4-card h4 {
    font-size: 30px;
    padding-top: 15px;
    font-family: "poppinsSemiBold";
}

.blockchainConsulting-page .sec-4 .sec4-container .sec4-card .sec4-card-2 {
    padding-top: 27px;
}

.blockchainConsulting-page .sec-4 .sec4-container .sec4-card .sec4-card-4 {
    padding-top: 17px;
}

.blockchainConsulting-page .sec-4 .sec4-container .sec4-card .sec4-card4-img {
    padding-top: 30px;
}

.blockchainConsulting-page .sec-4 .sec4-container .sec4-card p {
    font-size: 15px;
    padding-top: 5px;
    font-family: "poppins";
}

.blockchainConsulting-page .conclusion-section {
    background-color: #2979FF;
}

.blockchainConsulting-page .conclusion-section .conclusion-container {
    max-width: 1180px;
    margin-inline: auto;
    margin-top: 90px;
    padding-inline: 35px;
    padding-block: 33px;
    margin-bottom: 50px;
}

.blockchainConsulting-page .conclusion-section .conclusion-container h3 {
    font-size: 30px;
    color: #fff;
    font-family: "poppinsBold";
    text-align: center;
}

.blockchainConsulting-page .conclusion-section .conclusion-container p {
    color: #fff;
    font-family: poppins;
    text-align: center;
    padding-top: 10px;
}

/* White Label Blockchain Solutions Css End */

@media (max-width:1180px) {
    .blockchainConsulting-page .main-container {
        padding-inline: 40px;
    }

    .blockchainConsulting-page .main-container .main-left h2 {
        font-size: 55px;
    }

    .blockchainConsulting-page .main-container .main-left p {
        font-size: 15px;
    }

    .blockchainConsulting-page .main-container .main-right img {
        width: 450px;
    }
}

@media (max-width:992px) {
    .servicePage .serviceSec .serviceCard {
        max-width: 100%;
    }

    .fullGraySec .serviceCard {
        max-width: 100%;
    }

    .blockchainConsulting-page .main-container {
        flex-direction: column-reverse;
        text-align: center;
    }

    .blockchainConsulting-page .main-container .main-left h2 {
        font-size: 52px;
    }

    .blockchainConsulting-page .main-container .main-right img {
        width: 420px;
    }

    .blockchainConsulting-page .service-section .service-container1 {
        margin-top: 80px;
        flex-direction: column-reverse;
    }

    .blockchainConsulting-page .service-section .service-container2 {
        margin-top: 90px;
        flex-direction: column;
    }

    .blockchainConsulting-page .service-section .service-container1 .service-text {
        width: 80%;
    }

    .blockchainConsulting-page .service-section .service-container2 .service-text {
        width: 80%;
    }

    .blockchainConsulting-page .sec-4 .sec4-container {
        flex-direction: column;
    }

    .blockchainConsulting-page .sec-4 .sec4-container .sec4-card {
        width: 80%;
        height: 385px;
    }

    .blockchainConsulting-page .sec-4 .sec4-container .sec4-card .sec4-card4-img {
        padding-top: 0;
    }

    .blockchainConsulting-page .sec-4 .sec4-container .sec4-card .sec4-card-4 {
        padding-top: 0;
    }
}

@media (max-width:792px) {
    .blockchainConsulting-page .service-section .service-container1 {
        text-align: center;
    }

    .blockchainConsulting-page .service-section .service-container2 {
        text-align: center;
    }

    .blockchainConsulting-page .service-section h6 {
        font-size: 15px;
        padding-inline: 50px;
    }

    .blockchainConsulting-page .service-section h2 {
        font-size: 38px;
    }

    .blockchainConsulting-page .service-section .service-container1 .service-text {
        width: 90%;
    }

    .blockchainConsulting-page .service-section .service-container2 .service-text {
        width: 90%;
    }

    .blockchainConsulting-page .sec-4 h2 {
        font-size: 38px;
    }

    .blockchainConsulting-page .sec-4 h6 {
        font-size: 15px;
        padding-inline: 50px;
    }

}

@media (max-width:576px) {
    .servicePage .serviceSlider .contentCard p {
        font-size: 20px;
    }

    .servicePage .serviceSec .head {
        font-size: 30px;
    }

    .servicePage .serviceSec .head2 {
        font-size: 25px;
    }

    .servicePage .serviceSec .serviceCard h2 {
        font-size: 30px;
    }

    .servicePage .serviceSec .serviceCard {
        padding: 25px 15px;
    }

    .fullGraySec .serviceCard {
        padding: 25px 15px;
    }

    .servicePage .sevicesWeOffer2 h1 {
        font-size: 35px;
    }

    .blockchainConsulting-page .main-container .main-left h2 {
        font-size: 34px;
    }

    .blockchainConsulting-page .main-container .main-left p {
        font-size: 12px;
    }

    .blockchainConsulting-page .main-container .main-right img {
        width: 300px;
    }

    .blockchainConsulting-page .sec-2 .sec2-container {
        padding-inline: 40px;
    }

    .blockchainConsulting-page .service-section h2 {
        font-size: 30px;
    }

    .blockchainConsulting-page .service-section h6 {
        font-size: 14px;
        padding-inline: 20px;
    }

    .blockchainConsulting-page .service-section .service-container1 .service-text {
        padding-inline: 10px;
    }

    .blockchainConsulting-page .service-section .service-container2 .service-text {
        padding-inline: 10px;
    }

    .blockchainConsulting-page .service-section .service-container1 .service-text h4 {
        font-size: 25px;
    }

    .blockchainConsulting-page .service-section .service-container2 .service-text h4 {
        font-size: 25px;
    }

    .blockchainConsulting-page .service-section .service-container1 .service-text p {
        font-size: 15px;
        padding-top: 10px;
    }

    .blockchainConsulting-page .service-section .service-container2 .service-text p {
        font-size: 15px;
        padding-top: 10px;
    }

    .blockchainConsulting-page .service-section .service-container1 .service-text {
        width: 100%;
    }

    .blockchainConsulting-page .service-section .service-container2 .service-text {
        width: 100%;
    }

    .blockchainConsulting-page .sec-4 h2 {
        font-size: 25px;
    }

    .blockchainConsulting-page .sec-4 h6 {
        font-size: 12px;
        padding-inline: 20px;
    }

    .blockchainConsulting-page .sec-4 .sec4-container .sec4-card {
        width: 100%;
        height: 410px;
        padding-inline: 30px;
    }

    .blockchainConsulting-page .sec-4 .sec4-container .sec4-card h4 {
        font-size: 22px;
    }

    .blockchainConsulting-page .sec-4 .sec4-container .sec4-card p {
        font-size: 13px;
    }

}

@media (max-width:400px) {
    .blockchainConsulting-page .main-container .main-left h2 {
        font-size: 32px;
    }

    .blockchainConsulting-page .main-container .main-left p {
        font-size: 15px;
    }

    .blockchainConsulting-page .main-container .main-right img {
        width: 100%;
        height: auto;
    }

    .blockchainConsulting-page .service-section .service-container1 .service-img img {
        width: 100%;
        height: auto;
    }

    .blockchainConsulting-page .service-section .service-container2 .service-img img {
        width: 100%;
        height: auto;
    }

    .blockchainConsulting-page .sec-4 .sec4-container .sec4-card h4 {
        font-size: 20px;
    }

    .blockchainConsulting-page .sec-4 .sec4-container .sec4-card p {
        font-size: 11px;
    }
}