body {
    @apply font-poppins
}

.background-image {
    background-image: url('../../assets/images/cryptoPaymentDevelopment/background.png');
}
.evm-rectangle-gradient {
    background: linear-gradient(to top, #5C0CEC, #2979FF, #1FCBFF);
    background-size: 100% 150%;
  }