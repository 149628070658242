@font-face {
  font-family: poppins;
  src: url(./assets//Fonts/Poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: poppinsSemiBold;
  src: url(./assets//Fonts/Poppins/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: poppinsBold;
  src: url(./assets//Fonts/Poppins/Poppins-Bold.ttf);
}
@font-face {
  font-family: poppinsItalic;
  src: url(./assets//Fonts/Poppins/Poppins-Italic.ttf);
}

:root {
  --textDarkColor:#000000;
  --themeColor:#2979FF;
}

.font_poppins {
  font-family: 'poppins';
}


.navbar {
  /* background-color: #fff; */
  padding: 20px 0 !important;
  transition: all 0.2s;

}
.navbar .nav-link {
  font-family: poppinsSemiBold;
  color: #001637 !important;
  font-size: 14px;
  font-weight: 500;
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-weight: bold ;
}
.navbar .dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0; /* remove the gap so it doesn't close */
  background-color: var(--themeColor);
  left: 50%;
  transform: translate(-50%, 0);
}
.navbar .dropdown:hover .dropdown-menu .dropdown-item:focus{
  color: #000 !important;
}
.navbar .dropdown:hover .dropdown-menu .dropdown-item:active{
  color: #fff !important;
}
.navbar .dropdown:hover .dropdown-menu .dropdown-item:hover{
  color: var(--themeColor);
}
.navbar .dropdown:hover .dropdown-menu .dropdown-item{
  font-family: poppins;
  color: #fff;
  font-size: 14px;
}

.navbar .nav-link:hover,.navbar .nav-link.active {
  color: var(--themeColor) !important;
}
.navbar .dropdown-menu {
  border-radius: 12px;
}
.navContactBtn {
  display: flex;
  align-items: center;
}
.fs-0 {
  flex-shrink: 0;
}
.navContactBtn span:hover {
  background: #fff;
  color: var(--themeColor);
}
.navContactBtn span {
  border: 2px solid var(--themeColor);

  font-size: 20px;
  display: inline-block;
  width: 44px;
  height: 44px;
  background: blue;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: white;
  flex-shrink: 0;
  margin-right: 10px;
  cursor: pointer;
}
.themeBtn {
  background: var(--themeColor);
  max-width: 205px;
  width: 100%;
  padding: 9px;
  border-radius: 25px;
  font-size: 17px;
  font-weight: 500;  
  border: 2px solid var(--themeColor);
  color: white;
  text-align: center;
  font-family: poppinsSemiBold;
}
.themeBtn:hover {
  background: #fff;
  color: var(--themeColor);
}
.contactNumM {
  padding: 50px;
}
.contactNumM .contactImg {
  font-size: 20px;
  font-weight: 500;
}
.contactNumM {
  width: max-content;
  margin: auto;
}
.contactNumM .contactImg a:hover{
  color: var(--themeColor);
}
.contactNumM .contactImg a{
  color: black;
}
.contactNumM img {
  width: 50px;
  margin-right: 12px;
}
a{
  cursor: pointer;
}



.secondaryBtn {
  background: #6c757d;
  max-width: 205px;
  width: 100%;
  padding: 9px;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 500;  
  border: 2px solid #6c757d;
  color: white;
}
.secondaryBtn:hover {
  background: #fff;
  color: #6c757d;
}

.themeBtnOutline {
  font-family: 'poppinsSemiBold';
  background: #fff;
  max-width: 205px;
  width: 100%;
  padding: 9px;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 500;  
  border: 2px solid var(--themeColor);
  color: var(--themeColor);
}
.themeBtnOutline:hover {
  background: var(--themeColor);
  color: white;
}
.mainNavbar.bgColored {
  background-color: #fff;
  transition: all 0.2s;
  /* background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(14px); */
}
.secOne {
  background-image: linear-gradient(60deg, #D4E3FC, #FFFFFF);
  position: relative;
  padding-top: 150px;
  padding-bottom: 80px;
  color: white;
  z-index: 1;
}
.secOne .imgDiv {
  position: relative;
}
.secOne .imgDiv .settingIco1{
  position: absolute;
  top: 3%;
  width: 11%;
  max-width: 62px;
  transform: translate(-50%, 0);
  left: 33%;
   animation: loading 4s  infinite;
}
@keyframes loading {
  0% { 
    transform: rotate(0); 
  }
  100% { 
    transform: rotate(360deg);
  }
 }
.secOne .imgDiv .settingIco2{
  position: absolute;
  /* top: 9%; */
  top: 11%;
  width: 11%;
  max-width: 64px;
  transform: translate(-50%, 0);
  left: 23%;
   animation: loading1 4s  infinite;
}
@keyframes loading1 {
  0% { 
    transform: rotate(360deg);
  }
  100% { 
    transform: rotate(0); 
  }
 }
.secOne .imgDiv .settingIco3{
  position: absolute;
  top: 17%;
  width: 6%;
  max-width: 64px;
  transform: translate(-50%, 0);
  left: 36%;
   animation: linear loading2 5s infinite;
}
@keyframes loading2 {
  0% { 
    transform: rotate(360deg);
  }
  100% { 
    transform: rotate(0); 
  }
 }
.secOne h1 {
  font-family: poppinsBold;
  color: var(--textDarkColor);
  font-size: 65px;
    font-weight: bold;
    margin-bottom: 20px;
}
.secOne p{
  color: var(--textDarkColor);
  font-size: 21px;
  margin-bottom: 20px;
  font-family: "Poppins";
}
.secOne .slick-prev,.secOne .slick-next {
  display: none !important;
}
.secOne .secondImg .imgDiv{
  text-align: center;
}
.secOne .imgDiv .secondImg {
  max-width: 80%;
  margin: auto;
  padding-top: 140px;
  display: block !important;
} 
.ai-center {
  align-items: center;
}

.projectCounter1 {
  /* margin: 20px 0; */
  background-color: var(--themeColor);
}
.projectCounter1 .shadowMain{
  position: relative;

}
/* .projectCounter1 .shadowMain .shadow{
  background: linear-gradient(90deg, #6671ee 0%, #6171ee 50%, #5e71ee 100%);
  position: absolute;
  left: -4px;
  right: -4px;
  bottom: -4px;
  top: -4px;
  z-index: -1;
  filter: blur(12px);
} */

.projectCounter1 .counterMain {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-radius: 16px;
  overflow: hidden;
  /* box-shadow: 0 0 20px rgb(8 0 17 / 25%); */
  /* max-width: 900px;
  margin: auto; */
  padding: 35px 8px;
  border-radius: 20px;

}
.projectCounter1 .counterMain .imgDiv {
  margin-right: 20px;
}
.projectCounter1 .counterMain .imgDiv img{
  max-width: 65px;
}
.projectCounter1 .counterMain .imgDiv i{
  color: #fff;
}
.projectCounter1 .counterMain .countCard{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 25px 0;
  /* background-color: #fff; */
  width: 25%;
  /* background: linear-gradient(90deg, #eef2fd 0%, #ffffff 100%); */
}
.projectCounter1 .counterMain .countCard h3{
  font-family: 'poppinsBold';
  color: white;
  font-weight: 700;
  font-size: 40px;
  margin: 0;
  margin-bottom: 10px;
}
.projectCounter1 .counterMain .countCard p{
  font-family: 'poppins';
  color: white;
  margin-bottom: 0;
  font-size: 18px;
}
.secTitle{
  font-family: 'poppinsSemiBold';
  color: var(--themeColor);
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.secHeading{
  font-family: 'poppinsBold';
  color: var(--textDarkColor);
  font-size: 55px;
  margin-bottom: 30px;
  font-weight: 700;
}

.whatWeDo {
  padding: 80px 0;
}
.whatWeDo .weCard {
  position: relative;
  top: 0;
  border: 2px solid #EEEEEE;
  padding: 20px;
  /* min-height: 353.6px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s;
}
.whatWeDo .weCard:hover{
  transition: all 0.3s;
  top: -10px;
  box-shadow: 0px 5px 7px -3px rgba(0,0,0,0.4);

}
.whatWeDo .weCard .imgDiv{
  width: 110px;
}
.whatWeDo .weCard .title{
  font-size: 23px;
  font-weight: 500;
  margin: 20px 0;
  line-height: normal;
  font-family: 'poppinsSemiBold';
  
}
.whatWeDo .weCard .desc{
  font-family: 'poppins';
  font-size: 16px;
  color: rgba(51, 51, 51, 0.6);
  margin-bottom: 10px;
}
.whatWeDo .weCard .more{
  font-size: 16px;
  color: #333333;
  font-weight: 500;
}
.whatWeDo .btnRow {
  margin-top: 40px;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
}
.specialService {
  /* background: var(--themeColor); */
  padding: 80px 0;
}
.specialService .secHeading {
  color: #000;
}
.specialService .secHeadingSmall {
  color: var(--themeColor);
}
.specialService .specialCard {
  box-shadow: 0 0 4px rgb(8 0 17 / 22%);
  border-radius: 10px;
  max-width: 237px;
  padding: 30px 20px;
  margin: 20px auto;
}
.specialService .specialCard .title{
  margin-top: 20px;
  font-family: 'poppinsSemiBold';
  color: #000;
  font-size: 16px;
  font-weight: bold;
}
.specialService .specialCard .sImgRow{
  display: flex;
  align-items: center;
}
.specialService .specialCard img{
  margin-right: 10px;
}
.specialService .specialCard .desc{
  font-family: 'poppins';
  color: #333333;
  font-size: 13px;
  margin: 20px 0;
}
.specialService .specialCard .desc2{
  font-family: 'poppins';
  color: #000;
  font-size: 13px;
  margin: 20px 0;
}
.specialService .specialCard .desc2 a{
  color: #000;
}
.specialService .specialCard .demoBtn{
  /* background: #fff; */
  color: var(--themeColor);
  padding: 5px 13px;
  border-radius: 20px;
  border: 2px solid #fff;
  font-size: 10px;
  font-weight: 500;
}

.specialService .column {
  margin-top: 50px;
  margin-bottom: 50px;
}
.specialService .column:nth-child(2) {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}
.specialService .column:nth-child(5) {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}
.specialService .specialCard2 {
  max-width: 250px;
  padding: 30px 10px;
  margin: 20px auto;
}
.specialService .specialCard2 .title{
  margin-top: 20px;
  font-family: 'poppinsSemiBold';
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.specialService .specialCard2 .sImgRow{
  display: flex;
  align-items: center;
}
.specialService .specialCard2 img{
  margin-right: 10px;
}
.specialService .specialCard2 .desc{
  font-family: 'poppins';
  color: #fff;
  font-size: 13px;
  margin: 20px 0;
}
.specialService .specialCard2 .desc2{
  font-family: 'poppins';
  background-color: #fff;
  width: 120px;
  padding: 12px 10px;
  border-radius: 25px;
  font-size: 13px;
  margin: 20px 0;
  text-align: center;
}
.specialService .specialCard2 .desc2 a{
  color: #2979FF;
}
.specialService .specialCard2 .demoBtn{
  /* background: #fff; */
  color: var(--themeColor);
  padding: 5px 13px;
  border-radius: 20px;
  border: 2px solid #fff;
  font-size: 10px;
  font-weight: 500;
}




.ourProject {
  position: relative;
  padding: 40px 0;
  margin: 80px 0;
}
.ourProject .slick-next:before {
  color: #ffffff !important;
}
.ourProject .effect {
  background: var(--themeColor);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 55%;
  z-index: -1;
  border-top-left-radius: 140px;
}
.ourProject .desc {
  font-family: 'poppins';
  font-size: 20px;
  text-align: right;
  color: white;
}
.ourProject .projectImg img{
  max-width: 70%;
  max-height: 100%;
}
.ourProject .projectImg {
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  border-radius: 20px;
  text-align: center;
  padding: 15px 20px;
  background-color: white;
  /* margin-bottom: 15px; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
}



.teamSec {
  position: relative;
  padding: 60px 0;
}
.teamSec .effect {
  position: absolute;
  top: -100px;
  right: -600px;
}
.teamSec .content{
  width: 90%;
  margin-left: auto;
}

.teamSec h4 {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 0;
  font-family: 'poppinsSemiBold';
}

.teamSec h2 {
  font-family: 'poppinsBold';
  color: black;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-bottom: 50px;
}
.teamSec .desig {
  font-family: 'poppinsSemiBold';
  color: #2979FF;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.teamSec p {
  font-family: 'poppins';
  color: #222A41;
}

.teamSec .imgMain {
  display: flex;
  justify-content: center;
  max-width: 90%;
  margin-left: auto;
}
.teamSec .imgMain .col1{
  padding: 0 10px;
  
  position: relative;
  /* top: -100px; */
  width: 100%;

}
.teamSec .imgMain .col2{
  padding: 0 10px;
  width: 100%;
}
.teamSec .imgDiv.active {
  /* box-shadow: 0 0 13px rgb(8 0 17 / 15%); */
  transform: scale(1.1);
  border-top-left-radius: 40px;
  transition: all 0.3s;
}
.opacity0-5{
  opacity: 0.5;
}
.teamSec .imgDiv {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  /* max-height: 193.2px; */
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 2px;
  /* box-shadow: 0 0 13px rgb(8 0 17 / 8%); */
  /* background: white; */
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
}
.teamSec .imgMain img{
  max-width: 100%;
  width: auto;
}

.teamSec .imgDiv {
  position: relative;
}
.teamSec .imgDiv .content1 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 55%;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 10px;
  background: rgba(0,0,0,0);
  transition: all 0.3s;

}
.teamSec .imgDiv .content1 h3 {
  margin: 0;
  padding: 0;
  font-size: 18px;
}
.teamSec .imgDiv .content1 p {
  font-size: 14px;
  margin-bottom: 5px;
}
.teamSec .imgDiv .content1 a i{
    font-size: 18px;
}
.teamSec .imgDiv .content1 a {
  text-decoration: none;
  /* background: #002417; */
  color: #fff;
  padding: 5px;
  display: inline-block;
  transition: all 0.3s;
  transition-delay: 0s !important;

}
.teamSec .imgDiv .content1 a:hover {
    transition: all 0.3s;
    transform: scale(1.2) !important;
      transition-delay: 0s !important;

}
.lightMode .teamSec .imgDiv .content1 h3,
.lightMode .teamSec .imgDiv .content1 p,
.lightMode .teamSec .imgDiv .content1 a {
  
  color: #fff;
}
.teamSec .imgDiv .content1 h3,
.teamSec .imgDiv .content1 p,
.teamSec .imgDiv .content1 a {
  opacity: 0;
  transition: 0.5s;
  transform: translateY(20px);
  color: white;
}
.teamSec .imgDiv:hover .content1 h3 {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.2s;
}
.teamSec .imgDiv:hover .content1 {
  background: rgba(0,0,0,0.6);
  transition: all 0.3s;

}
.teamSec .imgDiv:hover .content1 p {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.5s;
}
.teamSec .imgDiv:hover .content1 a {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.7s;
}
.teamSec .arrowMain {
  display: flex;
  align-items: center;
  margin-top: 50px;
}
.teamSec .arrowMain img{
  cursor: pointer;
}
.teamSec .arrowMain .Circle.active {
  background-color: rgba(64,153,255,1);
}
.teamSec .arrowMain .circleM {
  display: flex;
  align-items: center;
  margin: 0 20px;
}
.teamSec .arrowMain .Circle {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin: 5px;
  background-color: rgba(64,153,255,0.2);
  cursor: pointer;
}
.teamSec .arrowMain .arrowMain img{
  cursor: pointer;
}
.teamSec .socialMain {
  display: flex; 
  align-items: center;
}
.teamSec .socialMain i{
  font-size: 20px;
}
.teamSec .socialMain a:hover{
  color: var(--themeColor);
  border: 3px solid var(--themeColor);
}
.teamSec .socialMain a {
  width: 35px;
  height: 35px;
  border: 3px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
  color: black;
}

 .testmonialSec {
  position: relative;
  padding: 60px 0;
  margin-bottom: 60px;
  padding-top: 80px;
}
.testmonialSec .effect {
  background: var(--themeColor);
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  /* width: 55%; */
  z-index: -1;
  height: 37%;
  border-top-left-radius: 60px;
}

 /* .testmonialSec h1 {
  font-weight: bold;
  font-size: 60px;
  margin-bottom: 30px;
  text-align: center;
} */

.testmonialSec .testCard {
   background-color: white;
  border-radius: 18px;
  /* box-shadow: 0px 0px 23px 0px rgb(236 86 35 / 42%); */
  border: 1px solid #E4EDFE;
  padding: 30px;
  color: #000;
  position: relative;
  top: 0;
  transition:all 0.3s;
  min-height: 288px;
}
 .testmonialSec .testCard:hover {
  top: -10px;
  transition:all 0.3s;
}
 .testmonialSec .testCard .starImg {
  width: 150px;
}

 .testmonialSec .testCard .desc.show5Line {
  max-height: 125px;
 }
 .testmonialSec .testCard .desc {
  font-family: 'poppinsItalic';
  font-size: 16px;
  font-style: italic;
  /* padding-top: 30px; */
  padding-bottom: 10px;
  opacity: 0.6;
  color: #333;
}
 .testmonialSec .testCard .profileDiv {
  display: flex;
  align-items: center;
  min-height: 72px;
}
 .testmonialSec .testCard .profileDiv img{
 width: 52px;
 height: 52px;
 border-radius: 50%;
}
.testmonialSec .testCard .profileDiv .name{
  font-family: 'poppinsSemiBold';
  font-size: 13px;
  font-weight: 500;
  color: #333333;
}
 .testmonialSec .testCard .profileDiv .desig{
  font-size: 13px;
  opacity: 0.6;
  color: rgba(51, 51, 51, 0.6);
}
.show5Line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5; /* number of lines to show */
  max-height: 186px;       /* fallback */
}
.slick-prev:before, .slick-next:before {
  color: var(--themeColor) !important;
}

.slick-dots li button:before {
  color: white !important;
  font-size: 9px !important;
}
.connectSec {
  padding: 60px 0;
  text-align: center;
}
.connectSec .desc {
  font-family: 'poppins';
  color: #8E8E8E;
  font-size: 18px;
  margin-bottom: 40px;
}
.connectSec .inputMain{
  background: #F2F2F2;
  border-radius: 35px;
  overflow: hidden;
  display: flex;
  align-items: center;
  max-width: 500px;
  margin: auto;
}
.connectSec .inputMain .themeBtn{
  max-width: none;
  width: auto;
  padding: 15px 30px;
  border-radius: 35px;
}
.connectSec .inputMain input:focus{
  outline: none;
}
.connectSec .inputMain input{
  border: none;
  width: 100%;
  padding: 10px 20px;
  background: transparent;
}

footer {
  font-family: 'poppins';
  position: relative;
  background: #F7F7F7;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  padding: 50px 0;
}
footer .footerRow {
  display: flex;
}
footer .footerRow .col1 .logoDiv{
  max-width: 154px;
}
footer .footerRow .col1 p {
  color: #000;
  font-size: 15px;
  margin: 20px 0;
}
footer .footerRow .col1 .mail:hover {
  color: #2979FF;
}
footer .footerRow .col1 .mail {
  color: #000;
  font-size: 15px;
  margin-bottom: 20px;
  display: block;
  text-decoration: none;
}
footer .footerRow .col1 .socialMain {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  max-width: 150px;
}
footer .footerRow .col1 .socialMain i{
  color: black;
  font-size: 22px;
}
footer .footerRow .col1 .socialMain a:hover {
  transform: scale(1.1);
}
footer .footerRow .col2 {
  margin-left: auto;
}
footer .footerRow h6 {
  font-family: 'poppinsSemiBold';
  color: #000;
  font-size: 15px;
  font-weight: bold;
}
footer .footerRow ul {
  list-style: none;
  padding: 0;
}
footer .footerRow ul li {
  padding: 5px 0;
}
footer .footerRow ul li a:hover {
  color: #2979FF;
}
footer .footerRow ul li a {
  color: #8E8E8E;
  font-size: 15px;
  text-decoration: none;
}
footer .footerRow .col3 {
  margin-left: 100px;
}
footer .footerRow .col4 {
  margin-left: 400px;
}

.modal-content {
  border-radius: 20px !important;
  overflow: hidden;
}
.contactModal {
  padding: 0 !important;
}
.contactModal2 {
  padding: 0 !important;
}
.contactModal2 .closeIco {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 1;
  width: 27px;
}
.contactModal h1{
  color: #000;
  font-weight: 700;
  text-align: center;
  margin-bottom: 60px;
  font-size: 40px;
  font-family: 'poppins';
}
.contactModal .callUs {
  color: #000;
}
.contactModal .feeFree{
  color: #000;
  font-size: 18px;
  text-align: center;
}
.contactModal .modal-body {
  padding: 0 !important;
}
.contactModal .modal-dialog {
  max-width: 800px !important;
}
.contactModal .closeIco{
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 1;
  width: 27px;
}
.contactModal .heading {
  /* background: url(./images/contactBg.webp); */
  background-size: cover;
  background-position: right;
  padding: 90px 30px 10px 30px;
}
.contactModal .col2 {
  padding: 60px 40px;
  display: flex;align-items: center;
}
.contactModal .col1 {
  padding: 20px 30px;
  background: linear-gradient(0deg,#fff 0%, #D4E3FC 100%) 0 100% transparent no-repeat;
}
.contactModal .mainContent {
  /* padding: 30px; */
  /* background-color: #EEF2FF; */
  background: #fff;

}
.contactModal .mainContent .contact{
  font-size: 26px;
  color: #000;
  line-height: 1.2;
  max-width: 200px;
  margin: auto;
  text-align: center;
  margin-bottom: 15px;
  font-family: PoppinsSemiBold;
}

.contactModal .mainContent .socialMain{
  text-align: center;
  margin: 20px 0;
  margin-bottom: 60px;
}
.contactModal .mainContent .socialMain a{
  text-decoration: none;
  color: transparent;
  margin-right: 8px;
}
.contactModal .mainContent i {
  padding: 0px 10px;
}

.contactModal .mainContent .fb {
  color: #4867AA;
  cursor: pointer;
  text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.4);
  transition: all ease-in-out 150ms;
}

.contactModal .mainContent .fb:hover {
  margin-top: -10px;
  text-shadow: 0px 16px 10px rgba(0, 0, 0, 0.3);
  transform: translate(0, -8);
}

.contactModal .mainContent .twitter {
  color: #1DA1F2;
  cursor: pointer;
  text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.4);
  transition: all ease-in-out 150ms;
}

.contactModal .mainContent .twitter:hover {
  margin-top: -10px;
  text-shadow: 0px 16px 10px rgba(0, 0, 0, 0.3);
  transform: translate(0, -8);
}

.contactModal .mainContent .contentDiv{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.contactModal .mainContent .contentDiv .imgDiv{
 margin-right: 8px;
}
.contactModal .mainContent .contentDiv .imgDiv img{
  width: 25px;
}
.contactModal .mainContent .contentDiv .contactDiv p{
  color: #707070;
  font-size: 13px;
  margin: 0;
}
.contactModal .mainContent .contentDiv .contactDiv a{
  color: #000;
  font-weight: bold;
}
.contactModal .form-control{
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
}
.contactModal input{
  color: #707070;
  border-radius: 25px;
  font-size: 13px;
  font-weight: 500;
  height: 45px;
  border-radius: 30px !important;
  border: 1px solid rgba(112, 112, 112, 0.3);

}
.contactModal select{
  color: #707070;
  font-size: 13px;
  font-weight: 500;
  height: 45px;
  border-radius: 30px !important;
}
.contactModal textarea{
  border-color: rgba(112, 112, 112, 0.3);
}
.contactModal textarea::placeholder{
  color: #707070;
  font-size: 13px;
  font-weight: 500;
}
.contactModal .sendBtn{
  background-color: #3364FB;
  color: white;
  font-weight: 500;
  padding: 8px 38px;
  border-radius: 20px;
}
.round-30 {
  border-radius: 30px !important;
}

 
.contactModal .reqMeetingMain .selectTopic{
  background: rgba(255, 255, 255, 0.1);
  border-radius: 27px;
  display: flex;
  color: white;
  padding: 6px;
  font-size: 14px;
  margin-bottom: 1rem;
}
.contactModal .reqMeetingMain ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.contactModal .reqMeetingMain .btnMain{
  display: flex;
  text-align: center;
  margin-top: 40px;
  justify-content: center;
}
.contactModal .reqMeetingMain .btnMain button{
  max-width: 150px;
  width: 100%;
}
.contactModal .reqMeetingMain .selectTopic .sBtn.active {
  background: #EC5623;
}
.contactModal .reqMeetingMain .selectTopic .sBtn{
  width: 100%;
  text-align: center;
  border-radius: 30px;
  padding: 8px;
  cursor: pointer;
}
.contactModal .reqMeetingMain .dateM .form-group{
  width: 100%;
}
.contactModal .modal-content{
  box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.25);
}
.contactModal .reqMeetingMain .dateM{
  display: flex;
}
.form-group {
  margin-bottom: 1rem;
}
a {
  text-decoration: none;
}
.arrowUp img{
  transition: all 0.3s;
}
.arrowUp:hover img{
  transform: scale(1.3);
  transition: all 0.3s;
}
.dib {
  display: inline-block;
}
.arrowUp {
  position: absolute;
  top: 0;
  width: 70px;
  height: 70px;
  background: blue;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translate(-50%, -35px);
  cursor: pointer;
}

@media(min-width:1400px){
  .secOne .imgDiv .settingIco1 {
    left: 28%;
  }
  .secOne .imgDiv .settingIco2 {
    left: 19%;
  }
  .secOne .imgDiv .settingIco3{
    left: 31%;
  }
}
@media(max-width:1200px) and (min-width:992px){
  .teamSec .imgDiv {
    max-height: 158px;
  }
}
@media(max-width:768px) and (min-width:576px){
  .ourProject .projectImg {
    height: 120px;
  }
  .teamSec .imgDiv {
    max-height: 151.28px;
  }
  .teamSec .content {
    width: 100%;
  }
  footer .footerRow .col3 {
    margin-left: 0;
  }
  footer .footerRow .col4 {
    margin-left: 0;
  }
  footer .footerRow .col2 {
    margin-left: auto;
    margin-right: auto;
  }
}
@media(max-width:992px) and (min-width:576px){
  .teamSec h2 {
    font-size: 50px;
  }
}
@media(max-width:768px){
  .teamSec .imgDiv .content1 {
    height: 59%;
  }
  .teamSec .viewBtn {
    margin-top: 20px;
  }
  .specialService .column {
    border-bottom: 1px solid #fff;
  }
  .specialService .column:nth-child(2) {
    border-left: none;
    border-right: none;
  }
  .specialService .column:nth-child(5) {
    border-left: none;
    border-right: none;
  }
  .specialService .column:nth-child(6) {
    border-bottom: none;
  }
  
}


@media screen and  (max-width:992px) and (min-width:768px) {
  .ourProject .effect {
    width: 50%;
  }
  .teamSec .teamElement {
    width: 230px;
    height: auto;
}

}
@media (max-width:992px){
  .secOne .imgDiv {
    text-align: center;
    margin-top: 30px;
  }
  .teamSec .content {
    margin-bottom: 40px;
  }
  
.collapsing {
  height: 100% !important;
}
/* .navbar-light .navbar-toggler {

} */
.mainNavbar .navbar-collapse.show{
  left: 0%;
}
.mainNavbar .navbar-collapse{
  position: fixed;
    /* inset: 0px; */
    background: white;
    z-index: 1;
    top: 0;
    min-height: 100vh;
    /* right: 0; */
    left: -100%;
    width: 100%;
    transition: all 0.5s;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
}
/* .collapse:not(.show) {
  display: flex !important;
} */
.navbar-toggler {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 11;
}
.navbar .nav-link {
  font-size: 22px;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
}


}
@media (max-width:576px) {
  .ourProject .slick-next:before {
    font-size: 15px !important;
  }
  .ourProject .projectImg img {
    max-width: 90%;
  }
  .ourProject .sliderMain {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ourProject .projectImg {
    height: 80px;
    padding: 20px 15px;
  }
  .slick-prev:before {
    color: #fff !important;
  }

  .teamSec .imgMain {
    max-width: 100%;
  }
  .whatWeDo .btnRow {
    /* flex-direction: column; */
    align-items: center;
  }
  /* .whatWeDo .btnRow .themeBtnOutline{
    margin-bottom: 20px;
  } */
  .projectCounter1 .counterMain .countCard {
    width: 50%;
  }
  .secOne h1 {
    font-size: 40px;
  }
  .secHeading {
    font-size: 30px;
  }
  .secTitle {
    margin-bottom: 10px;
  }
  .themeBtn {
    max-width: 160px;
    font-size: 16px;
  }
  .themeBtnOutline {
    max-width: 160px;
    font-size: 16px;
  }
  .ourProject .effect {
    left: 0;
    width: 100%;
    top: 90px;
    border-top-left-radius: 50px;
    
  }
  .ourProject .desc {
    font-size: 16px;
  }
  .teamSec .imgMain .col2 {
    padding: 0;
  }
  .teamSec .imgDiv {
    /* max-height: 164.2px; */
    max-height: auto;
  }
  .teamSec .imgMain {
    flex-direction: column;
  }
  .teamSec .imgMain .col1 {
      display: flex;
      top: 0;
      padding: 0;
      justify-content: center;
  }
  .teamSec .imgMain .col2 {
      display: flex;
      justify-content: center;
  }
  .teamSec .imgDiv .content1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .teamSec .imgDiv .content1 p {
      font-size: 13px;
  }
  .teamSec .imgDiv .content1 {
      height: 100%;
  }
  .teamSec .imgDiv:hover .content1 a {
      padding:0 7px;
  }
  .teamSec .imgDiv:hover .content1 a i {
      font-size: 14px;
  }
  .teamSec h2 {
    font-size: 40px;
  }
  .teamSec .content {
    width: 100%;
    margin-bottom: 40px !important;
  }
  .teamSec .teamElement {
    position: absolute;
    width: 200px;
    height: auto;
    top: -200px;
  }
  .teamSec .imgDiv {
      max-width: 170px;
      width: 100%;
      margin-left: 5px;
      margin-right: 5px;
  }
  footer .footerRow {
    flex-wrap: wrap;
  }
  footer .footerRow .col1 {
    width: 100%;
    margin-bottom: 30px;
  }
  footer .footerRow .col2 {
    margin-left: auto;
    margin-right: auto;
  }
  footer .footerRow .col3 {
    margin-left: auto;
    margin-right: auto;
  }
  footer .footerRow .col4 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }
}